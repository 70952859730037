import React, { useRef, useState, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import useInputSingleForm from '../../components/CustonHooks/inputFormHook';
import { SelectCustom } from '../../components/Helpers/SelectCustom';
import SearchCompanyEventType from './searchCompEventType';
import { companyEventTypeService } from '../../services/companyEventType.service';
import { useSelector } from 'react-redux';
import '../admin/admin.scss';
import { Button } from "rsuite";
import {FaPlus, FaSave, FaTimesCircle} from "react-icons/fa";
import {TitleAdmin} from "../components/titleAdmin/titleAdmin";
import {HeadingAdmin} from "../components/headingAdmin/headingAdmin";
import {PlusCircleOutlined} from "@ant-design/icons";

export function AdminCompanyEventType() {
    const companyId = useSelector(state => state.userSettings.companyId)
    const gridRef = useRef();
    const [showEdit, setShowEdit] = useState(false);
    const [errorReward, setErrorReward] = useState(false);
    const [errorNotification, setErrorNotification] = useState(false);
    let companyEventType = { CompanyEventTypeId: 0, EventTypeId: '', IsRewarded: false, Reward: 0, CompanyEventTypeNotificationId: 0, IsNotification: false }
    const [allowedToAddressCompanyEventTypes, setAllowedToAddressCompanyEventTypes] = useState([]);
    const onSave = () => {
        let model = {
            CompanyEventTypeId: parseInt(inputs.CompanyEventTypeId),
            EventTypeId: parseInt(inputs.EventTypeId),
            CompanyEventTypeNotificationId: parseInt(inputs.CompanyEventTypeNotificationId),
            IsRewarded: Boolean(inputs.IsRewarded),
            Reward: parseInt(inputs.Reward),
            IsNotification: Boolean(inputs.IsNotification),
            NotificationMessage: inputs.NotificationMessage,
            CompanyId: companyId,
            IsAudienceAddressed: Boolean(inputs.IsAudienceAddressed),
            OnNewUserRegistry: Boolean(inputs.OnNewUserRegistry)
        }

        if (model.IsRewarded && model.Reward == 0) {
            setErrorReward(true);
            return;
        }

        if (model.IsNotification & (model.NotificationMessage == '' || !model.NotificationMessage)) {
            setErrorNotification(true);
            return;
        }

        if (parseInt(inputs.CompanyEventTypeId) === 0) {
            companyEventTypeService.createCompanyActionType(model).then((result) => {

                gridRef.current.refreshDataGrid();
            });
        } else {
            companyEventTypeService.updateCompanyActionType(model).then((result) => {

                gridRef.current.refreshDataGrid();
            });
        }
        setInputs({});
        setShowEdit(false);
    };
    const { inputs, handleInputChange, handleSubmit, setInputs } = useInputSingleForm(onSave, companyEventType);

    const handleOnNewCompanyEvent = () => {
        setInputs(companyEventType);
        setShowEdit(true);
    };

    const handleOnCancel = () => {
        setInputs({});
        setShowEdit(false);
    }

    const handleRowSelect = (gridItem) => {
        setShowEdit(true);
        setInputs({
            CompanyEventTypeId: gridItem.companyEventTypeId,
            EventTypeId: gridItem.eventTypeId,
            CompanyEventTypeNotificationId: gridItem.companyEventTypeNotificationId,
            IsRewarded: gridItem.isRewarded,
            Reward: gridItem.reward,
            IsNotification: gridItem.isNotification,
            NotificationMessage: gridItem.notificationMessage,
            CompanyId: gridItem.companyId,
            Active: gridItem.active,
            IsAudienceAddressed: gridItem.isAudienceAddressed,
            OnNewUserRegistry: gridItem.onNewUserRegistry
        });

    }

    const getAllowedToAddressCompanyEventTypes = (props) => {
        companyEventTypeService.getAllowedToAddressCompanyEventTypes(props)
            .then(res => {
                setAllowedToAddressCompanyEventTypes(res);
                });
    }

    useEffect(() => {
        getAllowedToAddressCompanyEventTypes(companyId);
    }, [companyId]);

    useEffect(() => {
        setErrorNotification(false);
        setErrorReward(false);
    }, [inputs]);

    return (
        <>
            <TitleAdmin
                title={'Lista de tipos de evento'}
                subtitle={'Listado y alta de eventos'}
                btnBack={false}
            />
            <HeadingAdmin
                text={!(showEdit) ? 'Agregar tipo de evento' : 'Cancelar'}
                icon={(!showEdit) ? <PlusCircleOutlined /> : <FaTimesCircle />}
                event={() => !(showEdit) ? handleOnNewCompanyEvent() : handleOnCancel()}
            />
            <Row>
                {showEdit ?
                    <Col sm={12}>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col sm={12}>
                                    <h4>{inputs.CompanyEventTypeId == 0 ? "Nuevo tipo de evento" : "Editar tipo de evento"}</h4>
                                </Col>
                                <Col md={3}>
                                    <label for="Active">¿Tiene recompensa?</label>
                                    <div>
                                        <Input type="checkbox" onChange={handleInputChange} checked={inputs.IsRewarded} name="IsRewarded" id="Active" />
                                        <Label for="Active">Si, tiene recompensa</Label>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-dashboard-group">
                                        <Label for="EventType">Tipo de evento</Label>
                                        <SelectCustom selectName="EventTypeId" handleOnChange={handleInputChange} selectValue={inputs.EventTypeId}
                                            method={inputs.CompanyEventTypeId == 0 ? "EventType/Filter" : "EventType"} textField="eventName" valueField="eventTypeId" disabled={inputs.CompanyEventTypeId == 0 ? false : true} required />
                                    </FormGroup>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-dashboard-group">
                                        <Label for="Reward">Puntos
                                            <span className="invalid-feedback" style={{ 'display': 'initial' }}>{errorReward ? ' Requerido' : ''}</span>
                                        </Label>
                                        <Input name="Reward" placeholder="Puntos" onChange={handleInputChange} value={inputs.Reward} required={inputs.IsRewarded} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={3}>
                                    <label for="IsNotification">¿Genera notificación?</label>
                                    <div>
                                        <Input type="checkbox" onChange={handleInputChange} checked={inputs.IsNotification} name="IsNotification" id="IsNotification" />
                                        <label className="custom-control-label" for="IsNotification">Si, genera notificación</label>
                                    </div>
                                </Col>
                                <Col md={4}>
                                    <FormGroup className="form-dashboard-group">
                                        <Label for="NotificationMessage">
                                            Texto de la notificación
                                            <span className="invalid-feedback" style={{ 'display': 'initial' }}>{errorNotification ? ' Requerido' : ''}</span>
                                        </Label>
                                        <Input name="NotificationMessage" onChange={handleInputChange} value={inputs.NotificationMessage} required={inputs.isNotification} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            {allowedToAddressCompanyEventTypes.map(a => (a.eventTypeId)).includes(inputs.EventTypeId) &&
                            <Row>
                                <Col md={3}>
                                    <label className="enabled" for="IsAudienceAddressed">¿Seleccionará la audiencia?</label>
                                    <div>
                                        <Input  type="checkbox" onChange={handleInputChange} checked={inputs.IsAudienceAddressed} name="IsAudienceAddressed" id="IsAudienceAddressed" />
                                        <label className="custom-control-label" for="IsAudienceAddressed">Si, se selecciona audiencia</label>
                                    </div>
                                </Col>
                            </Row>
                            }
                            {inputs.IsAudienceAddressed ? 
                            allowedToAddressCompanyEventTypes.map(a => (a.eventTypeId)).includes(inputs.EventTypeId) &&
                                <Row>
                                    <Col md={3}>
                                        <label className='enabled' for='OnNewUserRegistry'>¿Se unirán nuevos registro?</label>
                                        <div>
                                            <Input  type="checkbox" onChange={handleInputChange} checked={inputs.OnNewUserRegistry} name="OnNewUserRegistry" id="OnNewUserRegistry"/>
                                            <label className="custom-control-label" for="OnNewUserRegistry">Si, se añadirán nuevos resgistros</label>
                                        </div>
                                    </Col>
                                </Row>
                            : null
                            }
                            <Col xs={24}>
                                <div className="form-backoffice-footer">
                                    <Button type="primary" htmlType={'submit'}>
                                        <p>Guardar</p>
                                        <FaSave />
                                    </Button>
                                </div>
                            </Col>
                        </Form>
                    </Col>
                    : null
                }
            </Row>
            <SearchCompanyEventType handleRowSelect={handleRowSelect} ref={gridRef} />
        </>
    );
}
